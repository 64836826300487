<template>
  <div class="classes-details-container" v-loading="loading">
    <RouterNav :name="name"/>
    <div class="classes-top">
      <div class="classes-top-grade">{{ gradeId | formatGradeName }}</div>
      <h6 class="classes-top-name">{{ name }}</h6>
      <span class="classes-top-id">(ID：{{ sequence }})</span>
      <img
          @click="copyClassId(sequence)"
          class="classes-top-copy"
          src="../../assets/icon-copy.png" alt="copy"/>
      <p style="flex: 1;"></p>
      <el-dropdown @command="handleCommand">
        <div class="classes-top-btn classes-top-more">
          <img class="classes-top__icon" src="../../assets/icon-dropdown.png"/>
          更多操作
        </div>
        <el-dropdown-menu class="classes-top__menu" slot="dropdown">
          <el-dropdown-item class="classes-top__menu-item" command="setting">班级设置
          </el-dropdown-item>
          <el-dropdown-item class="classes-top__menu-item" command="transfer">转让班级</el-dropdown-item>
          <el-dropdown-item class="classes-top__menu-item" command="dissolve">解散班级</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="classes-top-btn classes-top-group" @click="$router.push({name: 'ClassGroup'})">
        <img class="classes-top__icon" src="../../assets/icon-user-group.png"/>
        分组管理
      </div>
    </div>

    <div class="details-content">
      <div class="details-content__radio">
        <el-radio-group v-model="deviceType" size="medium" @change="handleChangeDeviceType">
          <el-radio-button label="user">个人端（{{ userTableData.length }}）</el-radio-button>
          <el-radio-button label="exam">考试端（{{ examineeCount || examTableData.length }}）</el-radio-button>
        </el-radio-group>
        <div :class="{'no-selected':!isSelected}" class="details-content__remove" @click="onRemoveStudents">
          <img class="details-content__remove-icon" src="../../assets/delete.png" alt=""/>
          移除{{ deviceType === 'user' ? '学生' : '考生' }}
        </div>
      </div>

      <div v-show="deviceType==='user'">
        <dl class="table-row">
          <dt style="width: 64px">
            <el-checkbox v-model="allSelected" @change="onSelectedAll"></el-checkbox>
          </dt>
          <dt style="width: 140px">学生姓名</dt>
          <dt style="width: 140px">学习卡类型</dt>
          <dt style="width: 140px">学习卡有效期</dt>
          <dt style="width: 140px">考号
            <el-popover
                placement="bottom"
                title=""
                width="220"
                trigger="hover"
                content="学生绑定考号后，即可以登录个人学生端完成线上模考和查看机房考试成绩。">
              <img slot="reference" class="table-row__question-icon" src="../../assets/mark.png" alt="?"/>
            </el-popover>
          </dt>
          <dt style="width: 180px">作业完成度/得分率</dt>
          <dt style="width: 240px">分组</dt>
        </dl>
        <dl class="table-row" v-for="item of userTableData" :key="item.id">
          <dd style="width: 64px" @click="item.selected=!item.selected">
            <el-checkbox v-model="item.selected" @change="item.selected=!item.selected"></el-checkbox>
          </dd>
          <dd style="width: 140px;cursor: default;">
            <popover-details :student-id="item.id" @rename="item.realName=$event">
              <div class="table-col--name">
                {{ item.realName }}
                <img src="../../assets/edit.png" alt="edit"/>
              </div>
            </popover-details>
          </dd>
          <dd style="width: 140px" @click="item.selected=!item.selected">
            <span class="col-FF3C30" v-if="item.expire==='1'">已过期</span>
            <template v-else>
              <span class="col-1DC060" v-if="item.paid">正式卡</span>
              <span class="col-309af2" v-else>体验卡</span>
            </template>
          </dd>
          <dd style="width: 140px" @click="item.selected=!item.selected">
            <span class="col-FF3C30" v-if="item.expire==='1'">{{ item.expireAt }}</span>
            <span class="col-1DC060" v-else>{{ item.expireAt }}</span>
          </dd>
          <dd style="width: 140px" @click="item.selected=!item.selected">
            <span v-if="item.examineeNum">{{ item.examineeNum }}</span>
            <span class="col-FF3C30" v-else>未绑定</span>
          </dd>
          <dd style="width: 180px" @click="item.selected=!item.selected">
            {{ item.activeRate }}%/{{ item.scoreRate }}%
          </dd>
          <dd style="width: 240px;cursor: default;">
            <span class="col-999" v-if="groupList.length===0">当前班级暂无分组</span>
            <select-group
                v-else
                width="184" :class-id="classId" :student-id="item.id"
                :all-groups="groupList" :groups="item.groupList || []"
                @updated="updatedUserAndGroup"/>
          </dd>
        </dl>
      </div>

      <div v-show="deviceType==='exam'">
        <dl class="table-row">
          <dt style="width: 64px">
            <el-checkbox v-model="allSelected" @change="onSelectedAll"></el-checkbox>
          </dt>
          <dt style="width: 245px">学生姓名</dt>
          <dt style="width: 245px">考号</dt>
          <dt style="width: 245px">考试次数/得分率</dt>
          <dt style="width: 245px">
            绑定个人端
            <el-popover
                placement="bottom"
                title=""
                width="220"
                popper-class="table-row__question"
                trigger="hover"
                content="学生考号和个人端账号绑定后，学生即可以登录个人学生端完成线上模考和查看机房考试成绩。">
              <img slot="reference" class="table-row__question-icon" src="../../assets/mark.png" alt="?"/>
            </el-popover>
          </dt>
        </dl>
        <dl class="table-row" v-for="item of examTableData"
            :key="item.id"
            @click="item.selected=!item.selected">
          <dd style="width: 64px">
            <el-checkbox v-model="item.selected" @change="item.selected=!item.selected"></el-checkbox>
          </dd>
          <dd style="width: 245px">{{ item.examineeName }}</dd>
          <dd style="width: 245px">{{ item.examineeNum }}</dd>
          <dd style="width: 245px">{{ item.examCount }}次/{{ item.examScoreRate }}%</dd>
          <dd style="width: 245px">
            <span class="col-1DC060" v-if="item.userId">已绑定</span>
            <span class="col-FF3C30" v-else>未绑定</span>
          </dd>
        </dl>
      </div>
    </div>

    <dialog-class
        v-if="classSettingDialogVisible"
        :visible="classSettingDialogVisible"
        :class-name="name"
        :class-id="classId"
        :grade-id="gradeId"
        :enrollment-year="enrollmentYear"
        :allow-join="allowJoin"
        @updated="loadUserData"
        @close="classSettingDialogVisible=false"/>
    <dialog-transfer-class
        v-if="transferClassDialogVisible"
        :visible="transferClassDialogVisible"
        :class-id="classId"
        :class-name="name"
        @close="transferClassDialogVisible=false"
        @success="$router.replace({name: 'ClassManage'})"/>
  </div>
</template>

<script>
import RouterNav from "@/components/RouterNav";
import {dissolveClass, getGradeClassDetail, getStudents, removeStudents, removeStudentsForExam} from "@/api/classes";
import DialogClass from "@/views/classes/components/dialogClass";
import DialogTransferClass from "@/views/classes/components/dialogTransferClass";
import SelectGroup from "@/views/classes/components/selectGroup";
import {getGroupList} from "@/api/group";
import PopoverDetails from "@/views/classes/components/popoverDetails";

export default {
  name: "ClassDetails",
  components: {PopoverDetails, SelectGroup, DialogTransferClass, DialogClass, RouterNav},
  data() {
    return {
      loading: false,
      classId: '',
      classSettingDialogVisible: false,
      transferClassDialogVisible: false,
      name: '',
      gradeId: '',
      section: '',
      sequence: '',
      enrollmentYear: '',
      allowJoin: null,
      deviceType: 'user',
      userTableData: [],
      examTableData: [],
      groupList: [],
      allSelected: false,
      examineeCount: 0
    }
  },
  computed: {
    isSelected() {
      // 利用计算属性判断是否全选逻辑
      if (this.deviceType === 'user') {
        this.allSelected = this.userTableData.length === 0 ? false : this.userTableData.every(item => item.selected)

        return this.userTableData.some(item => item.selected) || this.allSelected
      } else if (this.deviceType === 'exam') {
        this.allSelected = this.examTableData.length === 0 ? false : this.examTableData.every(item => item.selected)

        return this.examTableData.some(item => item.selected) || this.allSelected
      }
    }
  },
  async created() {
    this.classId = this.$route.params.id
    this.examineeCount = this.$route.query.examineeCount

    this.loading = true
    await this.loadUserData()

    // 加载分组数据
    await this.loadGroupData()
    this.loading = false
  },
  methods: {
    handleChangeDeviceType() {
      if (this.examTableData.length === 0 && this.deviceType === 'exam') {
        this.loadUserData('EXAMINEE')
      }
    },
    async loadUserData(type = 'STUDENT') {
      try {
        // const res = await getStudents(this.classId)

        const params = {
          clazzId: this.classId,
          type,
          complexData: true
        }
        const res = await getGradeClassDetail(params)
        if (res.state === '11') {
          const {data} = res
          this.name = data.name
          this.gradeId = data.gradeId
          this.section = data.year
          this.enrollmentYear = data.enrollmentYear
          this.sequence = data.sequence
          this.allowJoin = data.allowJoin
          if (type === 'STUDENT') {
            this.userTableData = data.userList.map(item => {
              item.selected = false
              return item
            })
          } else if (type === 'EXAMINEE') {
            this.examTableData = data.examineeList?.map(item => {
              item.selected = false
              return item
            }) || []
          }
        }
      } catch (e) {
      }
    },
    /**
     * 获取班级的分组列表
     */
    async loadGroupData() {
      try {
        const res = await getGroupList(this.classId)
        if (res.state === '11') {
          this.groupList = res.data
        }
      } catch (e) {
      }
    },
    /**
     * 复制班级 ID
     * @param id
     */
    copyClassId(id) {
      if (document.execCommand('copy')) {
        let input = document.createElement('input')
        input.value = id
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        input.style.display = 'none'
        this.$message.success('已复制班级ID')
      } else {
        this.$message.warning('该浏览器不支持，请手动复制。')
      }
    },
    /**
     * 更多操作下拉选择
     * @param command
     */
    handleCommand(command) {
      if (command === 'setting') {
        this.classSettingDialogVisible = true
      } else if (command === 'transfer') {
        this.transferClassDialogVisible = true
      } else if (command === 'dissolve') {
        this.$confirm('解散班级将清除当前班级的所有相关信息！', '提醒', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await dissolveClass(this.classId)
          if (res.state === '11') {
            this.$message.success('已成功解散班级')
            await this.$router.replace({name: 'ClassManage'})
          }
        }).catch(() => {
        })
      }
    },
    /**
     * 全选
     */
    onSelectedAll() {
      if (this.deviceType === 'user') {
        this.userTableData = this.userTableData.map(item => {
          item.selected = this.allSelected
          return item
        })
      } else if (this.deviceType === 'exam') {
        this.examTableData = this.examTableData.map(item => {
          item.selected = this.allSelected
          return item
        })
      }
    },
    /**
     * 移除学生 - 个人端
     */
    onRemoveStudents() {
      if (!this.isSelected) {
        return
      }
      this.$confirm('确认将所选学生移出班级？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (this.deviceType === 'user') {
          const ids = this.userTableData.filter(item => item.selected).map(item => item.id).join()
          const res = await removeStudents(ids)
          if (res.state === '11') {
            this.userTableData = this.userTableData.filter(item => !item.selected)
            this.$message.success('移除成功')
          }
        } else if (this.deviceType === 'exam') {
          const ids = this.examTableData.filter(item => item.selected).map(item => item.id)
          const res = await removeStudentsForExam(ids)
          if (res.state === '11') {
            this.examTableData = this.examTableData.filter(item => !item.selected)
            this.$message.success('移除成功')
          }
        }
      }).catch(() => {
      });
    },
    /**
     * 添加分组后，需更新学生列表/分组列表两个数据
     */
    updatedUserAndGroup() {
      this.loadGroupData()
      this.loadUserData()
      this.loadUserData('EXAMINEE')
    }
  }
}
</script>

<style lang="scss">
.details-content__radio {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px !important;

  .el-radio-button__inner {
    width: 140px;
    font-size: 15px;
    font-weight: normal;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #309AF2;
    font-weight: bold;
    background-color: #EAF4FD;
    border-color: #DCDFE6;
    -webkit-box-shadow: -1px 0 0 0 #DCDFE6;
    box-shadow: -1px 0 0 0 #DCDFE6;
  }
}

.table-row .el-checkbox__inner {
  width: 20px;
  height: 20px;
  background-color: #f6f6f6;
  border-radius: 6px;

  &::after {
    width: 4px !important;
    height: 8px !important;
    left: 6px !important;
    top: 3px !important;
    border-width: 2px;
  }
}
</style>

<style lang="scss" scoped>
.classes-details-container {
  width: 1100px;
}

.classes-top {
  display: flex;
  align-items: center;
  height: 84px;
  background-color: #FFFFFF;
  padding: 0 28px;
  margin-bottom: 10px;

  &-grade {
    width: 59px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    color: #309af2;
    font-size: 15px;
    background: #EAF4FD;
    border-radius: 4px;
    margin-right: 14px;
  }

  &-name {
    color: #333333;
    font-size: 17px;
    margin-right: 14px;
  }

  &-id {
    color: #333333;
    font-size: 13px;
    margin-right: 14px;
  }

  &-copy {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 40px;
    font-size: 15px;
    border-radius: 4px;
    cursor: pointer;
  }

  &-more {
    color: #309af2;
    border: 1px solid #309af2;

    &:hover {
      background-color: #eaf5fe;
    }
  }

  &-group {
    color: #FFFFFF;
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    margin-left: 14px;

    &:hover {
      background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
    }
  }

  &__menu {
    width: 116px;

    &-item {
      width: 116px;
      height: 41px;
      line-height: 41px;
      text-align: center;
      color: #333333;
      font-size: 15px;

      &:first-of-type {
        margin-top: 10px;
      }

      &:last-of-type {
        color: #FF3C30;
        margin-bottom: 10px;
      }
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.details-content {
  min-height: calc(100vh - 218px);
  background-color: #FFFFFF;
  padding: 20px 28px;

  &__remove {
    display: flex;
    align-items: center;
    color: #FF3C30;
    font-size: 15px;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 4px;

    &:hover {
      background-color: rgba(255, 60, 48, 0.1);
    }

    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }

  .no-selected {
    cursor: not-allowed;
    opacity: 30%;
  }
}

.table-row {
  display: flex;
  align-items: center;

  dt {
    color: #666666;
    background-color: #f6f6f6;
  }

  dt, dd {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }

  dd {
    cursor: pointer;
  }

  &__question-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-top: 4px;
    margin-left: 4px;
  }

  &:hover {
    background-color: #f6f6f6;
  }
}

.table-col--name {
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
    margin-left: 2px;
  }

  &:hover {
    color: #309af2;
  }
}
</style>
